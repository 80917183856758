<template>
  <i18n-t :keypath="keypathByType" tag="p" class="text" data-t="body-message">
    <template #amount>
      <b>{{ notificationAmount }}</b>
    </template>
  </i18n-t>
</template>

<script setup lang="ts">
import type { AccountTransactionNotification } from '../../../../types'

const props = defineProps<{
  notification: AccountTransactionNotification
}>()

const { notification } = toRefs(props)
const { notificationAmount } = useCurrencyNotification(notification)

const keypathByType = computed(() =>
  notification.value.data.operationType === 'freebetAccrual'
    ? 'notifications.body.freebetAccrual'
    : 'notifications.body.freebetWinAccrual',
)
</script>

<style scoped>
.text {
  margin: 0;
  font: var(--desktop-text-xs-medium);
  color: var(--text-secondary);

  b {
    font: var(--desktop-text-xs-semibold);
    color: var(--text-primary);
  }
}
</style>
